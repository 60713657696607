import cn from 'classnames'
import React, { ComponentType, FC, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import { CommerceProvider } from '@framework'
import { IDeviceInfo, useUI } from '@components/ui/context'
import type { Page } from '@commerce/types/page'
import { Navbar } from '@components/common'
import type { Category } from '@commerce/types/site'
import CartSidebarView from '@components/cart/CartSidebarView'
import { WishlistSidebarView } from '@components/wishlist'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { Sidebar, Button, Modal } from '@components/ui'
import s from './Layout.module.css'
import { getData } from '../../utils/clientFetcher'
import { getItem } from '../../utils/localStorage'
import { HomePageService } from 'services/homePage/index.service'
import NotifyUserPopup from '@components/ui/NotifyPopup'
import axios from '@services/axiosInterceptorInstance'
import {
  EmptyGuid,
  NEXT_GET_NAVIGATION,
  SESSION_LOGIN_PROMPT,
  valueForLoginLogout,
} from '@components/utils/constants'
import ProgressBar from '@components/ui/ProgressBar'
import useDataSubmit from '@commerce/utils/use-data-submit'
import LoginModalRouter from '@components/common/Navbar/LoginModalRouter'
import {
  BTN_ACCEPT_COOKIE,
  GENERAL_COOKIE_TEXT,
} from '@components/utils/textVariables'
import Spinner from '@components/ui/Spinner'
import { SCROLL_RANGE, ScrollTracker } from '@components/utils/ui/ScrollTracker'
import {
  getSessionItem,
  setSessionItem,
} from '@components/utils/sessionStorage'
import { LocalStorage } from '@components/utils/payment-constants'
import { stringToBoolean } from '@framework/utils/parse-util'
import LoginLogoutAskModal from '@components/account/LoginLogoutAskModal'
import Cookies from 'js-cookie'

import { microsoftClarity } from 'services/utils'
import { encrypt, decrypt } from '@framework/utils/cipher'

const Loading = () => (
  <div className="fixed z-50 flex items-center justify-center p-3 text-center w-80 h-80">
    <Spinner />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const FeatureBar: ComponentType<any> = dynamic(
  () => import('@components/common/FeatureBar'),
  {
    ...dynamicProps,
  }
)
const Footer = dynamic(() => import('@components/common/Footer'))

interface Props {
  pageProps: {
    pages?: Page[]
    categories?: Category[]
  }
  nav: []
  footer: []
  isLocationLoaded: boolean
  config: any
  keywords: []
}

const ModalView: FC<{ modalView: string; closeModal(): any }> = ({
  modalView,
  closeModal,
}) => {
  return (
    <Modal onClose={closeModal}>{modalView === 'NOTIFY_USER' && null}</Modal>
  )
}
interface IProps {
  deviceInfo: IDeviceInfo
}
const ModalUI: FC<IProps> = ({ deviceInfo }) => {
  const { displayModal, closeModal, modalView, notifyUser, productId } = useUI()
  if (notifyUser) return <NotifyUserPopup deviceInfo={deviceInfo} />
  if (displayModal)
    return <ModalView modalView={modalView} closeModal={closeModal} />
  return null
}

const SidebarView: FC<
  { sidebarView: string; closeSidebar(): any; config: any } & IExtraProps
> = ({ sidebarView, closeSidebar, config, deviceInfo, previousPath }) => {
  return (
    <Sidebar onClose={closeSidebar} deviceInfo={deviceInfo}>
      {sidebarView === 'CART_VIEW' && (
        <CartSidebarView
          config={config}
          deviceInfo={deviceInfo}
          previousPath={previousPath}
        />
      )}
      {sidebarView === 'WISHLIST_VIEW' && <WishlistSidebarView />}
    </Sidebar>
  )
}

const SidebarUI: FC<any> = ({ config, deviceInfo, previousPath }: any) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()
  return displaySidebar ? (
    <SidebarView
      sidebarView={sidebarView}
      closeSidebar={closeSidebar}
      config={config}
      deviceInfo={deviceInfo}
      previousPath={previousPath}
    />
  ) : null
}

interface LayoutProps {
  nav: []
  footer: []
}

export interface IExtraProps {
  readonly deviceInfo: IDeviceInfo
  readonly previousPath?: string
}

const Layout: FC<Props & IExtraProps> = ({
  children,
  config,
  pageProps,
  keywords,
  isLocationLoaded,
  deviceInfo,
  previousPath,
}) => {
  const [showLoginLogoutModal, setShowLoginLogoutModal] = useState(false)
  const categories = pageProps?.categories ?? []
  const [isLoading, setIsLoading] = useState(false)
  const {
    setSidebarView,
    isGuestUser,
    user,
    setAppConfig,
    closeSidebar,
    openCart,
    sidebarView,
    appVersionData,
  } = useUI()
  const [isScrollAtLast, setIsScrollAtBottom]: any = useState('')
  const [data, setData] = useState<any>({ nav: [], footer: [] })
  const { isOnlyMobile } = deviceInfo
  const sidebarType = getSessionItem('sidebarType')
  const isUserLoggedIn =
    (!isGuestUser && user?.userId && user?.userId !== EmptyGuid) || false
  const sessionLoaderState = getSessionItem('loaderState')
    ? JSON.parse(getSessionItem('loaderState'))
    : false
  const router = useRouter()
  const isChannelStore =
    router?.query?.channelStore || getItem(LocalStorage.Key.IS_OFFLINE_STORE)
      ? true
      : false
  const { state: submitState, dispatch: submitDispatch } = useDataSubmit()

  const isLoginPromptEnabled = appVersionData?.showloginprompt
    ? stringToBoolean(appVersionData?.showloginprompt)
    : false

  const handleLoginPrompt = () => {
    if (appVersionData?.loginpopuptime) {
      setTimeout(() => {
        setSidebarView('LOGIN_PROMPT_MODAL_VIEW')
        setSessionItem(SESSION_LOGIN_PROMPT, true)
      }, parseInt(appVersionData?.loginpopuptime, 10))
    }
  }

  useEffect(() => {
    const isLoginPromptVisible = getSessionItem(SESSION_LOGIN_PROMPT)
    if (
      isLoginPromptEnabled &&
      !isLoginPromptVisible &&
      !isUserLoggedIn &&
      isOnlyMobile &&
      !isChannelStore
    ) {
      handleLoginPrompt()
    }
  }, [isLoginPromptEnabled])

  useEffect(() => {
    const fetchLayout = async () => {
      try {
        const response: any = await getData(NEXT_GET_NAVIGATION)
        setData(response)
        // setItem('navTree', response)
      } catch (error) {
        console.error(':: error ', error)
      }
    }
    fetchLayout()
    setAppConfig(config)
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeStart', () => setIsLoading(true))
    Router.events.on('routeChangeComplete', () => setIsLoading(false))
    if (!Cookies.get(LocalStorage.Key.userEncryptedDetails)) {
      const access_token =
        localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
      access_token &&
        Cookies.set(LocalStorage.Key.userEncryptedDetails, access_token)
    }
  }, [])

  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { locale = 'en-US', asPath, ...rest } = useRouter()

  const sortedData: any = data?.nav?.sort(
    (a: any, b: any) => a.displayOrder - b.displayOrder
  )
  const checkIfCartUrl = asPath?.includes('/cart')
  const isCartShouldOpen = asPath?.includes('#cartopen')

  const getTheNewLogin = async (mobileNumber: string) => {
    const detailedInfo = encrypt(
      JSON.stringify({
        username: mobileNumber,
      })
    )
    const data = {
      detailedInfo,
      isChannelStore,
    }
    const res: any = await HomePageService.refreshPageContent({
      data: data,
    })
    const tempValue: any =
      res?.data?.result && JSON.parse(decrypt(res?.data?.result))
    if (tempValue?.userToken) {
      Cookies.set(LocalStorage.Key.userEncryptedDetails, tempValue?.userToken)
      localStorage.setItem(
        LocalStorage.Key.userEncryptedDetails,
        tempValue?.userToken
      )
    }
  }

  useEffect(() => {
    if (!isOnlyMobile) {
      if (isCartShouldOpen && !checkIfCartUrl) {
        if (sidebarView === '' || sidebarView === 'CART_VIEW') {
          openCart()
        }
      } else {
        closeSidebar()
      }
    }
  }, [asPath, sidebarView])

  useEffect(() => {
    const isLoginOpen = asPath?.includes('#loginOpen')
    // if (!isChannelStore) {
    if (!isUserLoggedIn) {
      if (isLoginOpen) {
        setSidebarView(sidebarType)
      } else {
        if (sidebarType?.includes('LOGIN')) {
          setSidebarView('')
        }
      }
    } else {
      if (isLoginOpen) {
        const newUrl = asPath?.replaceAll('#loginOpen', '')
        Router?.replace(
          {
            pathname: newUrl,
          },
          newUrl,
          {
            shallow: true,
            scroll: false,
          }
        )
      }
      // }
    }
  }, [asPath])

  const handleClarityUser = async () => {
    const started = await microsoftClarity.hasStarted()
    if (started) {
      const userMobile =
        user?.mobile || user?.telephone || user?.phone || user?.username
      microsoftClarity.setTag('user', encrypt(userMobile?.toString()))
    }
  }

  useEffect(() => {
    if (user?.userId && isUserLoggedIn) {
      if (!localStorage?.getItem(LocalStorage.Key.userEncryptedDetails)) {
        getTheNewLogin(user.username)
      }
      handleClarityUser()
    }
  }, [isUserLoggedIn, user?.userId])

  const askForLoginLogout = stringToBoolean(Cookies.get(valueForLoginLogout))
  useEffect(() => {
    if (asPath?.includes('channelStore=true')) {
      const isLoginOpen = asPath?.includes('#loginOpen')
      if (!showLoginLogoutModal && !isLoginOpen) {
        setShowLoginLogoutModal(true)
      }
    }
  }, [askForLoginLogout])

  if (sessionLoaderState) {
    return (
      <main className="bg-white">
        <div className="fixed top-0 right-0 z-50 flex items-center justify-center w-screen h-screen">
          <div className="w-32 h-32 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin" />
        </div>
      </main>
    )
  }
  return (
    <CommerceProvider locale={locale}>
      {asPath === '/' ? (
        <ScrollTracker
          isScrollAtLast={isScrollAtLast}
          setIsScrollAtLast={(newIsScrollAtLast: SCROLL_RANGE) => {
            setIsScrollAtBottom(newIsScrollAtLast)
          }}
        />
      ) : null}
      {isLoading && <ProgressBar />}
      <div className={cn(s.root)}>
        {!checkIfCartUrl ? (
          <Navbar
            currencies={config?.currencies}
            config={sortedData}
            languages={config?.languages}
            keywords={keywords}
            deviceInfo={deviceInfo}
            previousPath={previousPath}
            key="navbarKeyId"
          />
        ) : null}
        <main className="fit">{children}</main>
        {!checkIfCartUrl ? (
          <Footer config={data.footer} deviceInfo={deviceInfo} />
        ) : null}
        <ModalUI deviceInfo={deviceInfo} />
        <SidebarUI
          config={config}
          deviceInfo={deviceInfo}
          previousPath={previousPath}
        />
        <FeatureBar
          title={GENERAL_COOKIE_TEXT}
          hide={acceptedCookies}
          action={
            <Button className="mx-5" onClick={() => onAcceptCookies()}>
              {BTN_ACCEPT_COOKIE}
            </Button>
          }
        />
        {!checkIfCartUrl ? null : (
          <LoginModalRouter
            submitState={submitState}
            submitDispatch={submitDispatch}
            deviceInfo={deviceInfo}
          />
        )}
      </div>
      {showLoginLogoutModal && askForLoginLogout && (
        <LoginLogoutAskModal
          deviceInfo={deviceInfo}
          modalTitle={'Relogin'}
          show={askForLoginLogout && showLoginLogoutModal}
          isOnlyMobile={isOnlyMobile}
          close={() => {
            setShowLoginLogoutModal(false)
            Cookies.set(valueForLoginLogout, '')
          }}
        />
      )}
    </CommerceProvider>
  )
}

export default Layout

import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { getCurrentPage } from '@framework/utils/app-util'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useDebounce } from 'hooks/useDebounce'

export interface IProps {
  setIsScrollAtLast: (isScrollAtLast: SCROLL_RANGE) => void
  isScrollAtLast: SCROLL_RANGE
}

export enum SCROLL_RANGE {
  FROM_0_TO_20 = '0-20',
  FROM_20_TO_40 = '20-40',
  FROM_40_TO_60 = '40-60',
  FROM_60_TO_80 = '60-80',
  FROM_80_TO_100 = '80-100',
}

export const ScrollTracker: FunctionComponent<IProps> = ({
  setIsScrollAtLast,
  isScrollAtLast,
}) => {
  const trackScrollDepth = () => {
    // Get the height of the entire document
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    )

    // Calculate the scroll position based on the current scroll position and viewport height
    const scrollPosition =
      window?.pageYOffset || document.documentElement.scrollTop
    const windowHeight =
      window?.innerHeight || document.documentElement.clientHeight

    const calScrollDepth =
      ((scrollPosition + windowHeight) / documentHeight) * 100

    const scrollDepth = Math.floor(calScrollDepth / 20) * 20
    switch (scrollDepth) {
      case 0:
        handleScrollDepthGAEvent(SCROLL_RANGE.FROM_0_TO_20, calScrollDepth)
        break
      case 20:
        handleScrollDepthGAEvent(SCROLL_RANGE.FROM_20_TO_40, calScrollDepth)
        break
      case 40:
        handleScrollDepthGAEvent(SCROLL_RANGE.FROM_40_TO_60, calScrollDepth)
        break
      case 60:
        handleScrollDepthGAEvent(SCROLL_RANGE.FROM_60_TO_80, calScrollDepth)
        break
      case 80:
        handleScrollDepthGAEvent(SCROLL_RANGE.FROM_80_TO_100, calScrollDepth)
        break
      default:
        // Handle cases outside the specified ranges or any other custom logic.
        break
    }
  }

  const handleScrollDepthGAEvent = (
    range: SCROLL_RANGE,
    calScrollDepth: number
  ) => {
    if (isScrollAtLast !== range) {
      const currentPage = getCurrentPage()
      setIsScrollAtLast(range)
      if (currentPage) {
        if (typeof window !== 'undefined') {
          recordGA4Event(window, 'scroll_depth', {
            current_page: currentPage,
            scrollThreshold: Math.round(calScrollDepth),
            range: range,
            scrollUnits: 'percent',
            scrollDirection: 'vertical',
          })
        }
      }
    }
  }

  const trackScrollDepthDebounce = useCallback(
    useDebounce(trackScrollDepth, 200),
    []
  )

  useEffect(() => {
    // Attach the trackScrollDepth function to the scroll event
    window?.addEventListener('scroll', trackScrollDepthDebounce)

    // Clean up the event listener on component unmount
    return () => {
      window?.removeEventListener('scroll', trackScrollDepthDebounce)
    }
  }, [isScrollAtLast])

  return null
}
